<template>
    <button ref="button" class="empty-button show-focus-state" @click="handleOpenMenu" @mouseenter="hover = true;" @mouseleave="hover = false;">
        <q-icon class="icon" :class="[{ hover, active, animation }, animation]" :type="icon" :variation="variation" :width="iconWidth" :height="iconHeight" :color="iconColor"></q-icon>
    </button>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'q-icon-button',
    props: {
        icon: {
            type: String,
            required: true
        },
        variation: {
            type: String,
            default: null
        },
        iconWidth: {
            type: String | Number,
            default: 22
        },
        iconHeight: {
            type: String | Number,
            default: 22
        },
        iconColor: {
            type: String,
            default: '#484f56'
        },
        active: {
            type: Boolean,
            default: false
        },
        animation: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            hover: false
        }
    },
    methods: {
        handleOpenMenu(event) {
            this.$emit('click');
            this._handleBlur();
        },
        _handleBlur: _.debounce(function() {
            if(this.$refs.button) this.$refs.button.blur();
        }, 500)
    }
}
</script>

<style scoped lang="scss">
@import '@/components/qds/assets/style/_variables.scss';

.empty-button {
    position: relative;
    border: none;
    background: none;
    border: none;
    padding: 3px;
    cursor: pointer;
    border-radius: 50%;
    aspect-ratio: 1/1;
    isolation: isolate;

    &:focus-visible .icon,
    .icon.hover {
        transition: 600ms ease;
        color: $color-grey-7;

        &:before {
            scale: 1;
            transition: scale 600ms cubic-bezier(.15,.75,.2,1);
        }
    }

    &:active .icon:before {
        scale: 0.85 !important;
        transition: scale .2s ease;
    }
}

.icon {
    color: $color-grey-5;
    transition: color 300ms ease;

    &:before {
        content: "";
        position: absolute;
        inset: -10%;
        transform-origin: center center;
        background: #c4c4c460;
        scale: 0;
        border-radius: 50%;
        z-index: -1;
        transition: scale 300ms ease;
    }
}

.animation {
    &.rotate {
        transition: color 300ms ease, transform 600ms ease;

        &.active {
            transform: rotate(270deg);
            -webkit-transform: rotate(270deg);
            transition: color 300ms ease, transform 800ms cubic-bezier(.45,-0.74,.4,1) !important
        }
    }
}

@keyframes rotateWheel {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    } 20% {
        transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
    } 100% {
        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
    }
}

</style>