<template>
    <div :class="['inputGroup', variation, size, labelPosition, { 'inside-label-padding': labelPosition === 'inside' && hasInsideLabel}]">
        <label v-if="labelPosition !== 'inside'" :class="['outsideLabel', size]">
            <slot />
        </label>
        <div class="labelWrap" :data-direction="direction" :class="[{ disabled }, state, variation, { invalid }]" :style="`--percentage-x: ${percentageX}%; --percentage-y: ${percentageY}%; --bubble-scale: ${bubbleScale}`">
            <div ref="hitBox" class="interactive"></div>
            <div class="input-wrap">
                <div v-if="inputType === 'currency' || unit === '€'" class="unit prefix" :class="size">&euro;</div>
                <div v-else-if="unitPosition === 'prefix'" class="unit prefix" :class="size">{{ unit }}</div>
                <div 
                    v-if="inputType === 'currency'|| unit === '€'" 
                    ref="currencyInput"
                    class="currency-input" 
                    :class="[size, variation, { disabled }]" 
                    @keydown="validateKeydown"
                    @input="handleCurrencyInput"
                    @blur="handleCurrencyBlur"
                    :contenteditable="!disabled"
                ></div>
                <input
                    v-else
                    :type="parsedInputType"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :class="['input', state, variation, size, { 'inside-label-padding': labelPosition === 'inside' && hasInsideLabel }]"
                    :data-direction="direction"
                    :id="elementId"
                    :value="value"
                    :min="min"
                    :max="max"
                    @input="handleInput"
                    @blur="handleBlur"
                    @focus="handleFocus"
                    ref="input"
                />
                <div v-if="inputType !== 'currency' && unit && unit !== '€' && unitPosition === 'suffix'" class="unit suffix" :class="size">{{ unit }}</div>
                <label v-if="labelPosition === 'inside'" class="insideLabel" :class="size" ref="insideSlot">
                    <slot />
                </label>
            </div>
            <div class="special-border">
                <span class="button-bubble" :class="{ hide: !initializedPosition }"></span>
            </div>
        </div> 
        
        <div v-if="state === `error` || state === `success` || invalid" class="icon_divmedium">
            <q-tooltip v-if="state === `error` || invalid" position="top">
                <template v-slot:tooltip>
                    <p v-if="errorMessage">{{ errorMessage }}</p> 
                    <p v-else-if="parsedInputType === 'password'">Onjuist wachtwoord</p>
                    <p v-else-if="parsedInputType === 'email'">Onjuist e-mailadres</p>
                    <p v-else>Er is iets misgegaan!</p>
                </template>
                <q-icon
                    class="errorIcon"
                    type="danger"
                    :width="iconWidth"
                    :height="iconHeight"
                ></q-icon
            ></q-tooltip>

            <q-icon v-else-if="state === `success`" class="successIcon" type="check" width="18" height="18"></q-icon>
        </div>
    </div>
</template>

<script>

import { getMoney } from '@/assets/js/utils.js';

/**
 * inputs are generally used for interface actions. Suitable for all-purpose use.
 * Defaults to appearance that has white background with grey border.
 * Primary style should be used only once per view for main call-to-action.
 */
export default {
    name: 'q-input',
    status: 'prototype',
    release: '0.0.1',
    props: {
        /**
         * Value prop, used for v-model
         */
        value: {
            type: [String, Number]
        },
        /**
         * Type of input
         * `text, password, email`
         */
        inputType: {
            type: String,
            default: 'text',
            validator: value => {
                return value.match(/(text|password|email|number|currency)/);
            }
        },
        /**
         * Disables inputfield
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Change placeholder for input
         *
         */
        placeholder: {
            type: String,
            default: 'Vul hier iets in...'
        },
        /**
         * The size of the input. Defaults to medium.
         * `small, medium`
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => {
                return value.match(/(small|medium|large)/);
            }
        },
        /**
         * Manually trigger various states of the input.
         * `hover, focus, error, success`
         */
        state: {
            type: String,
            default: 'default'
        },
        /**
         * Style variation to give additional meaning.
         * `primary, blank`
         */
        variation: {
            type: String,
            default: 'primary',
            validator: value => {
                return value.match(/(primary|blank)/);
            }
        },
        /**
         * InputId provides an 'id' value for the input attribute.
         */
        inputId: {
            type: String,
            default: null
        },
        errorMessage: {
            type: String,
            default: ''
        },
        labelPosition: {
            type:String,
            default: 'inside'
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        unit: {
            type: [String, Number],
            default: null
        },
        unitPosition: {
            type: String,
            default: 'suffix'
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER
        },
        // when true, the value inside the input will be selected on focus event
        selectOnFocus: {
            type: Boolean,
            default: false
        },
        direction: {
            type: String,
            default: 'ltr'
        }
    },
    data() {
        return {
            parsedInputType: '',
            hasInsideLabel: false,
            percentageX: 0,
            percentageY: 0,
            bubbleScale: 0.5,
            initializedPosition: false,
            regex: null,
            invalid: false,
            elementId: this.inputId,
            caretLocation: 0,
            previousStartOffsetMatches: 0,
            currentStartOffsetMatches: 0
        }
    },
    methods: {
        handleInput(event) {
            let value = event.target.value;
            if(this.inputType === 'number' && !isNaN(value)) value = parseFloat(value);
            this.$emit('input', value);

            this._validateInput(event.target);
        },
        _validateInput: _.debounce(function(input) {
            this.validateInput(input);
        }, 400),
        validateInput(input) {
            let value = input.value;
            let restrictedValue = false;
            if(value < this.min) input.value = this.min;
            if(value > this.max) input.value = this.max;
            if(input.value !== value) restrictedValue = true;
            
            value = input.value;
            if(this.inputType === 'number' && !isNaN(value)) value = parseFloat(value);
            if(restrictedValue) this.$emit('input', value);
            
            let validString = true;
            if(value !== '') {
                switch(this.parsedInputType) {
                    case'email':
                        validString = Boolean(value.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i));
                        break;
                    case'password':
                        const hasNumber = /\d/.test(value);
                        const hasLowercase = /[a-z]/.test(value);
                        const hasUppercase = /[A-Z]/.test(value);
                        const hasSpecial = /[\W_]/.test(value);
                        validString = hasNumber && hasLowercase && hasUppercase && hasSpecial;
                        break;
                    default:
                        break;
                }
            }

            this.invalid = !validString;

            this.$emit('error', !validString);
        },
        inputCurrencyValue() {
            const value = this.$refs.currencyInput.innerHTML;
            const strippedPoints = value.replaceAll('.','');
            const convertedToFloat = strippedPoints.replaceAll(',','.');
            this.$emit('input', convertedToFloat);
        },
        handleBlur(event) {
            this.validateInput(event.target);

            let value = event.target.value;
            if(this.inputType === 'number' && !isNaN(value)) value = parseFloat(value);

            this.$emit('blur', value);
        },
        handleCurrencyBlur(event) {
            const value = event.target.innerHTML;
            const strippedPoints = value.replaceAll('.','');
            const convertedToFloat = strippedPoints.replaceAll(',','.');
            this.$emit('blur', convertedToFloat);
        },
        handleMouseMove(event) {
            if(this.disabled) return
            this.initializedPosition = true;
            const hitBox = this.$refs.hitBox;
            const { x, y, width, height } = hitBox.getBoundingClientRect();
            if(
                hitBox.offsetParent === null ||
                (x + width - 30 - window.scrollX) > window.innerWidth || 
                (x + 30 - window.scrollX) < 0 || 
                (y + height - 30 - window.scrollY) > window.innerHeight ||
                (y + 30 - window.scrollY) < 0
            ) return

            const pixelsFromLeft = event.pageX - window.scrollX - x - 30;
            const pixelsFromtop = event.pageY - window.scrollY - y - 30;
            this.percentageX = pixelsFromLeft / (width - 60) * 100;
            this.percentageY = pixelsFromtop / (height - 60) * 100;
            const centerX = width / 2 - 30;
            const centerY = height / 2 - 30;
            const deltaCenterX = pixelsFromLeft < centerX ? centerX - pixelsFromLeft : pixelsFromLeft - centerX;
            const deltaCenterY = pixelsFromtop < centerY ? centerY - pixelsFromtop : pixelsFromtop - centerY;
            const percentageCenterX = deltaCenterX / (centerX) * 130;
            const percentageCenterY = deltaCenterY / (centerY) * 100;
            const bubbleScale = 1 - (((percentageCenterX + percentageCenterY) / 200) * 0.7);
            if(bubbleScale > 0) this.bubbleScale = bubbleScale;
        },
        validateKeydown(event) {
            const validCodes = ['Backspace','Comma','ArrowLeft','ArrowRight'];
            const validNumber = (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105);
            if(!validCodes.includes(event.code) && !validNumber) return event.preventDefault();

            if(this.value >= Number.MAX_SAFE_INTEGER && event.code !== 'Backspace') return
            
            const element = this.$refs.currencyInput;
            if(event.code === 'Enter') return element.blur();

            if(event.code === 'Comma') {
                event.preventDefault();
                this.caretLocation++;
                this.setCaret(this.caretLocation);
            }

            let { startOffset, endOffset } = window.getSelection().getRangeAt(0);
            let hasSelection = startOffset !== endOffset;
            if(hasSelection) return

            const isStandingOnComma = startOffset === element.innerHTML.length - 2;
            const isStandingOnPoint = element.innerHTML.substring(startOffset-1, startOffset) === '.';

            if(event.code === 'Backspace' && (isStandingOnComma || isStandingOnPoint)) {
                event.preventDefault();
                this.caretLocation--;
                this.setCaret(this.caretLocation);
            }

            this.previousStartOffsetMatches = this.getPointMatches();
        },
        getPointMatches() {
            let { startOffset } = window.getSelection().getRangeAt(0);
            const value = this.$refs.currencyInput.innerHTML;
        
            const startOffsetMatches = value.substring(0, startOffset).match(/\./g)?.length || 0;

            return startOffsetMatches
        },
        handleCurrencyInput(event) {
            let { startOffset, endOffset } = window.getSelection().getRangeAt(0);
            let hasSelection = startOffset !== endOffset;
            this.caretLocation = startOffset;
            
            this.updateCurrencyDisplay();
            
            this.currentStartOffsetMatches = this.getPointMatches();

            const pointAdded = this.currentStartOffsetMatches > this.previousStartOffsetMatches;
            const pointRemoved = this.previousStartOffsetMatches > this.currentStartOffsetMatches;
            
            let currentLocation = startOffset;

            const value = this.$refs.currencyInput.innerHTML;
            
            if(!isNaN(parseInt(event.data))) {
                if(hasSelection) currentLocation = startOffset;
                else if(pointAdded) currentLocation++;
            } else if(event.data === ',') {
                if(currentLocation === value.length - 2) currentLocation++;
            } else if(event.inputType === 'deleteContentBackward') {
                if(hasSelection) currentLocation -= selectionMatches;
                else if(pointRemoved) currentLocation--;
            }

            this.caretLocation = currentLocation;

            this.updateCurrencyDisplay();

            this.inputCurrencyValue();
        },
        async setCaret(caretIndex) {
            this.caretLocation = caretIndex;
            const element = this.$refs.currencyInput;
            element.focus();

            var range = document.createRange();
            try {
                range.setStart(element.childNodes[0], caretIndex);
            } catch(e) {
                if(!element.childNodes[0]) return element.focus()
                range.setStart(element.childNodes[0], element.childNodes[0].length)
            }
            var sel = window.getSelection();
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        },
        updateCurrencyDisplay(useAnswer) {
            let value = this.$refs.currencyInput.innerHTML;
            if(useAnswer && this.value) value = this.value.toString().replace('.',',');
            else if(!value) return this.$refs.currencyInput.innerHTML = '';

            const strippedPoints = value.replaceAll('.','');
            const convertedToFloat = strippedPoints.replace(',','.');
            
            const string = getMoney(convertedToFloat);
            this.$refs.currencyInput.innerHTML = string.substring(2, string.length);

            this.setCaret(this.caretLocation);
        },
        getCaretPosition() {
            var range = window.getSelection().getRangeAt(0);
            return range.startOffset
        },
        setAutofocus() {
            this.$nextTick(() => {
                if(this.inputType === 'currency') {
                    const element = this.$refs.currencyInput
                    element.focus();
                    this.setCaret(element.innerHTML.length);
                }
                else document.getElementById(this.elementId)?.focus();
            })
        },
        handleFocus(event) {
            if(!this.selectOnFocus) return

            const element = event.target;
            element.select();
        }
    },
    computed: {
        iconWidth() {
            return this.variation === 'blank' ? 16 : 20;
        },
        iconHeight() {
            return this.variation === 'blank' ? 16 : 20;
        }
    },
    created() {
        if(!this.elementId) this.elementId = Math.random() * 100000;
        let inputType = this.inputType;
        if(inputType === 'currency') inputType = 'number';
        this.parsedInputType = inputType;
    },
    mounted() {
        this.hasInsideLabel = this.$refs.insideSlot?.hasChildNodes();
        if(this.inputType === 'currency' || this.unit === '€') this.updateCurrencyDisplay(true);
        if(this.autofocus) this.setAutofocus();
        this.$root.$on('mousemove', this.handleMouseMove);
    },
    beforeDestroy() {
        this.$root.$off("mousemove", this.handleMouseMove);
    },
    watch: {
        value: function() {
            if(this.inputType === 'currency' || this.unit === '€') this.updateCurrencyDisplay(true);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

.inputGroup {
    font-weight: $weight-normal;
    font-size: 11px;
    font-family: $font-text;
    line-height: $line-height-m;
    position: relative;
    padding: 10px 0;
    margin: 1px;
    width: calc(100% - 1px);

    .outsideLabel {
        font-family: Gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #495057;

        &:empty {
            display: none;
        }
    }

    &.inside-label-padding {
        .labelWrap {
            input:not(:placeholder-shown) + .insideLabel,
            input:-webkit-autofill + .insideLabel {
                top: 4px;
                translate: 0 0;
                transition: 0.2s ease;
                visibility: visible;
            }

            input[data-autocompleted] {
                background: inherit;
            }
        
            input:not(:placeholder-shown),
            input:-webkit-autofill {
                transition: padding .2s ease;

                &.medium {
                    padding-top: 18px;
                    padding-bottom: 6px;
                }
        
                &.small {
                    padding-top: 16px;
                    padding-bottom: 2px;
                }
            }

            input {
                &.medium {
                    padding-block: 12px;
                }
        
                &.small {
                    padding-block: 9px;
                }
            }
        
            input:placeholder-shown + .insideLabel {
                top: 50%;
                translate: 0 -50%;
                visibility: hidden;
            }

            .insideLabel {
                position: absolute;
                user-select: none;
                pointer-events: none;
                padding: 0 !important;
                left: 8px;

                &.medium {
                    font-size: 11px !important;
                }
        
                &.small {
                    font-size: 9px !important;
                }
            }
        }
    }

    .labelWrap {
        @include reset;
        //   @include stack-space($space-m);
        //   @include inline-space($space-xs);
        will-change: transform;
        transition: all 0.2s ease;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: $font-text;
        line-height: $line-height-m;
        text-decoration: none;

        position: relative;
        display: flex;


        --border-radius: 4px;
        --border-width: 1px;
        --width: 60%;
        --time: 1s;

        --extra-padding-left: 0px;
        --extra-padding-right: 0px;

        // VARIATIONS

        &[data-direction=ltr] {
            --extra-padding-right: 14px;
        }
        &[data-direction=rtl] {
            --extra-padding-left: 14px;
        }

        .medium {
            --padding-block: 8px;
            --padding-inline: 10px;
            padding-block: var(--padding-block);
            padding-left: calc(var(--padding-inline) + var(--extra-padding-left));
            padding-right: calc(var(--padding-inline) + var(--extra-padding-right));
            font-size: $size-m;
        }
        .xsmall {
            padding-block: 0 !important;

            .labelWrap .input {
                --padding-block: 4px;
                --padding-inline: 4px;
                padding-block: var(--padding-block) !important;
                padding-left: calc(var(--padding-inline) + var(--extra-padding-left)) !important;
                padding-right: calc(var(--padding-inline) + var(--extra-padding-right)) !important;
                
                &::placeholder {
                    font-size: $size-s;
                }
            }
        }

        .small {
            --padding-block: 4px;
            --padding-inline: 6px;
            padding-block: var(--padding-block);
            padding-left: calc(var(--padding-inline) + var(--extra-padding-left));
            padding-right: calc(var(--padding-inline) + var(--extra-padding-right));

            &::placeholder {
                font-size: 13px;
            }
        }

        .large {
            --padding: 12px;
            padding-block: var(--padding);
            padding-left: calc(var(--padding) + var(--extra-padding-left));
            padding-right: calc(var(--padding) + var(--extra-padding-right));
            font-size: 18px;

            &::placeholder {
                font-size: 18px !important;
            }
        }

        &.blank {
            border: none !important;

            .input-wrap {
                background-color: lighten($color-grey-3, 7%);

                input {
                    color: $color-grey-7;
                    background-color: lighten($color-grey-3, 7%);

                    &.error {
                        background-color: #ffe0e0 !important;
                    }
                }
            }

            .special-border {
                display: none;
            }
            
        }

        &.blank > .icon_divmedium {
            right: 8px !important;
            margin-top: 1px;
        }

        .unit {
            max-width: 8ch;
            user-select: none;
            pointer-events: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            cursor: default;

            &:empty {
                display: none;
            }

            &.prefix {
                &.xsmall {
                    padding: 0 0 0 4px;
                }
                &.small {
                    padding: 0 0 0 8px;
                }
                &.medium {
                    padding: 0 0 0 12px;
                }
                &.large {
                    padding: 0 0 0 18px;
                }
            }
            &.suffix {
                &.small {
                    padding: 0 4px 0 4px;
                }
                &.medium {
                    padding: 0 12px 0 12px;
                }
                &.large {
                    padding: 0 18px 0 18px;
                }
            }
        }

        input {
            flex-grow: 1;
            outline: transparent;
            border: none;
        }

        input[type=number] {
            position: relative;
            // padding-right: 4px;
        }

        input[type=number]::-webkit-outer-spin-button, 
        input[type=number]::-webkit-inner-spin-button {
            width: 25px;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            background: red;
        }

        input[type=number][data-direction=rtl]::-webkit-outer-spin-button, 
        input[type=number][data-direction=rtl]::-webkit-inner-spin-button {
            right: unset;
            left: 0;
        }

        &.disabled,
        input:disabled {
            .input-wrap {
                background-color: lighten($color-grey-3, 7%);
            }
        }

        .insideLabel:empty {
            display: none;
        }

        //Placeholder

        &::placeholder,
        &.placeholder {
            color: $color-grey-7;
            font-size: $size-m;
        }

        //STATE

        &:focus,
        &.focus {
            border: 1px solid $color-primary;
            box-shadow: none;
            outline: 0;
        }

        &:focus-within,
        &:active {
            .special-border {
                .button-bubble {
                    width: 300% !important;
                    transition: width var(--time) ease-out;
                }
            }
        }

        &:focus-within {
            .button-bubble {
                transition: width var(--time) ease-out;
                left: 50% !important;
                top: 50% !important;
            }
        }

        &:active {
            .button-bubble {
                transition: left var(--time) ease, top var(--time) ease, width var(--time) ease-out !important;
            }
        }

        &:hover,
        &.hover {
            .special-border {
                .button-bubble {
                    background-color: $color-primary;
                }
            }
        }

        &.error,
        &.invalid:not(:focus-within) {
            animation: invalid-shake .3s;

            .special-border {
                --border-width: 2px;

                .button-bubble {
                    width: 300% !important;
                    background-color: $color-red;
                    left: 50%;
                    top: 50%;
                    transition: width var(--time) ease-out, background-color .2s ease;
                }
            }
        }

        // magical animation logic
        .interactive {
            position: absolute;
            inset: -30px;
            pointer-events: none;
        }

        .input-wrap {
            display: flex;
            align-items: center;
            border-radius: var(--border-radius);
            overflow: hidden;
            flex-grow: 1;
            background: white;
            position: relative;

            .currency-input {
                flex-grow: 1;
                font-size: 14px;
                line-height: 20px;
                min-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                user-select: none !important;

                &.disabled {
                    pointer-events: none;
                    user-select: none;
                }

                &:focus-visible {
                    outline: none;
                }
            }

            input {
                flex: 1;
                min-width: 0;
            }
        }

        .special-border {
            position: absolute;
            inset: calc(var(--border-width) * -1);
            pointer-events: none;
            background: $color-grey-5;
            border-radius: calc(var(--border-radius) + var(--border-width));
            z-index: -1;
            overflow: hidden;
            transition: inset .2s ease;;
            --width: 60%;

            .button-bubble {
                border-radius: 100%;
                position: absolute;
                display: block;
                content: '';
                background-color: $color-primary;
                z-index: -1;
                width: calc(var(--width) * var(--bubble-scale));
                aspect-ratio: 3/2;

                left: var(--percentage-x);
                top: var(--percentage-y);

                translate: -50% -50%;

                &.hide {
                    --width: 0px;
                }
            }
        }
    }

    .icon_divmedium {
        position: absolute;
        display: grid;
        place-items: center;
        top: 50%;
        translate: 0 -50%;
        right: 15px;
    }

    .icon {
        translate: 10% 10%;
    }

    .errorIcon {
        display: grid;
        place-items: center;
        color: $color-red;
    }

    .successIcon {
        display: grid;
        place-items: center;
        color: $color-green;
        scale: 0.9;
        margin-top: -2px;
    }
}

@keyframes invalid-shake {
    20%, 60% {
        transform: translateX(4px);
    } 40%, 80% {
        transform: translateX(-4px);
    } 0%, 100% {

    }
}
</style>

<docs>
  ```jsx
  <div>
  
    <q-input placeholder="Vul hier je e-mailadres in..." size="medium">E-mailadres</q-input>
    <q-input placeholder="Vul je voornaam in..." size="small">Naam</q-input>
    <q-input inputType= "password" placeholder="Wachtwoord..." size="medium">Wachtwoord</q-input>
    <q-input placeholder="Disabled" disabled>Disabled</q-input>
    
    <q-input placeholder="Hover..." state="hover">Hover</q-input>
    <q-input placeholder="Focus..." state="focus">Focus</q-input>
    <q-input placeholder="Error..." state="error">Ohjeeee</q-input>
    <q-input placeholder="Success!" state="success">Alriiiight</q-input>
    <q-input inputType="password" size="small" placeholder="Nieuw wachtwoord" variation="blank"></q-input>
    <q-input state="error" size="small" placeholder="Voornaam" variation="blank"></q-input>
    <q-input placeholder="Nieuw wachtwoord" variation="blank" disabled></q-input>

  </div>
  ``` 
</docs>
